import { Block, Heading,  Paragraph } from 'suomifi-ui-components';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { AuthProvider } from '../../provider/AuthProvider';

const LogoutView = () => {

  useEffect(() => {    
    AuthProvider.signout().then(x => {
      //debugger;
    })      
  }, []);

  return (
    <Block>
      <Heading variant="h1">{t('logoutView.title')}</Heading>  
      <Paragraph>
        <Link to='/'>{t('logoutView.redirect')}</Link>
      </Paragraph>
    </Block> 
  )
}

export default LogoutView