import { VCE_API } from "./api/vce-api/vce-api";

const vceApiContainer: { vceApi?: VCE_API<unknown> } = { vceApi: undefined };

const myFetch: typeof fetch = async (...fetchParams: Parameters<typeof fetch>) => {

  const response = await fetch(...fetchParams);
  if (response.status === 401) {
    try {
      const result = await vceApiContainer.vceApi!.sessions.refreshTokens({ credentials: "include" }); // same-origin
      console.log(result);
      if (result.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        if (window.location.pathname !== "/login")
          window.location.pathname = "/login";  
      }
    }
    catch {
      // // TODO use react router to route?
      // debugger;
      // localStorage.clear();
      // sessionStorage.clear();
      // //window.location.href = "/login";
      // if (window.location.pathname !== "/login")
      //   window.location.pathname = "/login"; 
      return response;
    }
    const responseReTry = await fetch(...fetchParams);
    return responseReTry;
  }
  return response;
};


const baseUrl = process.env.REACT_APP_VCE_API_URL; // create react app
//const baseUrl = import.meta.env.VITE_APP_VCE_API_URL; // vite react
const vceApi = new VCE_API({ customFetch: myFetch, baseUrl: baseUrl || "/api" });

vceApiContainer.vceApi = vceApi;

export default vceApi;