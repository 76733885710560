import { Box, Grid } from '@mui/material'
import { useState } from 'react'
import { ActionFunction, Form, redirect, useActionData, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { Button, IconLogin, TextInput, Notification, LoadingSpinner } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { SimplifiedPresentationPayload, VCE_API } from '../../api/vce-api/vce-api'
import { AuthProvider } from '../../provider/AuthProvider'
import RequestCredential from '../../components/RequestCredential'
import vceApi from '../../vceApi'


export interface IActionData {
  error?: string,
  datetime: Date
}

const LoginView = () => {
  const actionData = useActionData() as IActionData | undefined;
  const navigation = useNavigation();
  const navigate = useNavigate();
  //const location = useLocation();
  const { t } = useTranslation();
  //const loading = navigation.state === "loading";
  const submitting = navigation.state === "submitting";

  const [hideErrorDateTime, setHideErrorDateTime] = useState<Date>(new Date()); // TODO

  const doSuomiFiAuthentication = () => {
    const path = window.location.origin.replace(".net",".fi") + "/suomifi?authentication=true";
    // Get session (minisuomi is using test.minisuomi.fi server, so include credentials)
    vceApi.sessions.getSessionInfo({credentials: "include"}).then(async (x) => {
      console.log("already logged in to test.minisuomi.fi")
      debugger;
      const data = await x.json();
      if (data === null) {
        vceApi.sessions.getAuthorizeUrl({ relayState: path }).then(x => {      
          x.json().then(x => {
            if (x.redirect_uri) {
              window.location.href = x.redirect_uri;
            }
            console.log("ERROR: failed to get redirect uri!");
          });
        })
      }
      else {
        AuthProvider.setSessionInfo(data);
        const searchParams = new URLSearchParams(document.location.search)
        const redirectpath = searchParams.get("redirect");
        
        navigate(redirectpath?.startsWith("/") ? redirectpath : "/");
      }
    }).catch(x => {
      debugger;
      console.log("failed to get existing session, doing suomi.fi authentication...")
      vceApi.sessions.getAuthorizeUrl({ relayState: path }).then(x => {      
        x.json().then(x => {
          if (x.redirect_uri) {
            window.location.href = x.redirect_uri;
          }
          console.log("ERROR: failed to get redirect uri!");
        });
      })
      // console.log("not logged in to test.minisuomi.fi");
      // // trigger log in
      // vceApi.sessions.getAuthorizeUrl({ relayState: path }).then(x => {      
      //   x.json().then(x => {
      //     if (x.redirect_uri) {
      //       window.location.href = x.redirect_uri;
      //     }
      //     console.log("ERROR: failed to get redirect uri!");
      //   });
      // })
    })
    
  }

  const onRequestCallback = (data: SimplifiedPresentationPayload) => {
    const family_name = data.credentials[0].vc?.credentialSubject?.family_name;
    const given_names = data.credentials[0].vc?.credentialSubject?.given_name || data.credentials[0].vc?.credentialSubject?.given_names;
    const username = `${given_names} ${family_name}`;
    
    AuthProvider.signin(username);    
    navigate("/");
  }

  return (
    <>
      <Box sx={{
        marginTop: 2,
        padding: 2,
        margin: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>

        <RequestCredential callback={ onRequestCallback } credentialType='PID' claimsCSV='family_name,given_name' title="PID" client_id_scheme='did'></RequestCredential>

        {/* TODO <RequestMultipleCredentials callback={ onRequestCallback } credentialType='PID,LPID' claimsCSV='family_name,given_name' title="PID" client_id_scheme='did'></RequestCredential> */}
          

        <hr style={{ margin: "20px", width: "300px" }} />

        <Button onClick={doSuomiFiAuthentication}>Suomi.fi (SSO)</Button>

        <hr style={{ margin: "20px", width: "300px" }} />

        {/* Basic login */}
        {/* <p style={{ textAlign: "center" }}>Basic</p> */}
        <Box>
          {/* <Box component="form" method="post" action={location.pathname} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} width={320}> */}
          <Form method='post'>
            <TextInput className='input--custom'
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus labelText={t("loginView.email")} />
            <TextInput className='input--custom'
              required
              fullWidth
              name="password"
              labelText={t("loginView.password")}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <br />
            <Button iconRight={<IconLogin />}
              disabled={submitting}
              type="submit"
              fullWidth
              variant="default"
            >
              {t("login")}
            </Button>

          </Form>

          <Box height={60} mt={3}>

            {submitting && <LoadingSpinner
              forwardedRef={undefined}
              status="loading"
              text="lataa"
              textAlign="right"
              variant="normal"
            />
            }
            <Grid container maxWidth="xs">
              <Grid item>
                {actionData && actionData.datetime > hideErrorDateTime && actionData.error && <Notification
                  closeText="Sulje"
                  headingText={t("loginView.failed")}
                  status="error"
                  onCloseButtonClick={() => { setHideErrorDateTime(new Date()) }}
                >
                  {actionData.error}
                </Notification>
                }
              </Grid>

            </Grid>
          </Box>
        </Box>
      </Box>



    </>
  );
}

export const loginPostAction = (/*userContext: IUserData*/): ActionFunction => async ({ request }): Promise<Response | IActionData> => {

  const data = Object.fromEntries(await request.formData());
  const username = (data.email as string).split("@")[0].split(".").map(x => x && x.length > 1 ? x[0].toUpperCase() + x.substring(1) : "").join(" ").replace("  ", " ");

  try {
    await AuthProvider.signin(username);    

    const searchParams = new URLSearchParams(document.location.search)
    const redirectpath = searchParams.get("redirect");
    return redirect(redirectpath?.startsWith("/") ? redirectpath : "/");
  }
  catch (err: any) {
    return { error: err.message, datetime: new Date() } as IActionData
  }
}

export default LoginView
