import React, { useEffect, useState } from 'react'
import vceApi from '../vceApi';
import QRCode from 'react-qr-code';
import { Button, TextInput } from 'suomifi-ui-components';
import { t } from 'i18next';

type Props = { 
  issuer: string,
  credentialType: string,
  credentialConfigurationId: string,
  credentialSubject: string //{ [key: string]: unknown }
}

/** 
 * DO NOT USE THIS IN PRODUCTION! 
 * Issuing should happen in back channel, and there should be also issuer related apikey or something else to prevent anyone to issue credentials who can access the API
 * MiniSuomi environment has only test data 
 **/
const IssueCredential = ({ issuer, credentialType, credentialConfigurationId, credentialSubject }: Props) => {

  const [error, setError] = useState<string>("");
  const [credentialOfferUrl, setCredentialOfferUrl] = useState<string>("");
  const [walletAddress, setWalletAddress] = useState<string>("");
  //const [credentialType, setCredentialType] = useState<string|undefined>(undefined);

  useEffect(() => {
    console.log("IssueCredential effect");
    const issue = async () => {
      if (!issuer || !credentialConfigurationId) return;
      try {
  
        const issueResponse = await vceApi.issuers.issue(issuer, credentialConfigurationId, JSON.parse(credentialSubject), { validFrom: new Date().toISOString() /*validFrom?.toISOString()*/, validTo: undefined /*validTo?.toISOString()*/});
        if (!issueResponse.data)
          issueResponse.data = await issueResponse.text();
  
        const offerUri = issueResponse.data;
        
        setCredentialOfferUrl(offerUri);
      }
      catch(e) {
        console.log(e);
        setError(JSON.stringify(e));
      }
    }
    issue();
  }, []);// [credentialConfigurationId,issuer,credentialSubject]);

  // TODO renew button and time out (5min)

  const onSendCredentialToWalletClick = () => {
    if (!walletAddress)
      return;
    debugger;

    // Trim wallet address and try to post open id request to the address
    const walletAddressTrimmed = walletAddress.trim();
    const credential_offer_uri = new URL(credentialOfferUrl).searchParams.get("credential_offer_uri");
    //credential_offer_uri.
    //const decodedUri = decodeURIComponent(credentialOfferUrl);
    const issuer_state = credential_offer_uri?.substring(credential_offer_uri.lastIndexOf("?id=")+4);
    if (walletAddressTrimmed.length > 0 && issuer_state) {
      
      vceApi.issuers.initiateCredentialOffer("prh", { walletAddress: walletAddressTrimmed, issuer_state: issuer_state })
      /*let holderUrl = walletAddress;
      localStorage.setItem("walletAddress", holderUrl);
      if (walletAddressTrimmed.startsWith("did:web:")) { 
        // TODO what if localhost is https? is it ok to have credential_offer?
        holderUrl = (walletAddressTrimmed.indexOf("localhost")>=0 ? "http://" : "https://") + decodeURIComponent(walletAddressTrimmed.substring(8).replaceAll(":", "/"))
        if (!holderUrl.endsWith("/credential_offer")) {
          holderUrl = holderUrl + "/credential_offer";
        }
      }
      else {
        holderUrl = walletAddressTrimmed;
      }*/

      /*const issuerDomain = true ? "http://localhost:3000" : "https://test.minisuomi.fi";
      const credentialOfferJson = { 
        credential_issuer: `${issuerDomain}/api/issuers/prh`, 
        credential_configuration_ids: ["UniversityDegree_JWT","org.iso.18013.5.1.mDL"],
        grants: {
          "urn:ietf:params:oauth:grant-type:pre-authorized_code": {"pre-authorized_code":"oaKazRN8I0IbtZ0C7JuMn5","tx_code":{}
        }}
      }

      holderUrl = `${holderUrl}?credential_offer=${credentialOfferJson}`;
      
      fetch(holderUrl,)*/
      
    }
  }

  return (
    <div>
      <span style={{ color: "red" }}>{ error }</span>
      
      { credentialOfferUrl && !error &&
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 192, width: "100%" }}>
          <a href={credentialOfferUrl}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={credentialOfferUrl}
              viewBox={`0 0 256 256`}
            />
          </a>
          <p style={{textAlign: "center", margin: 0}}>{credentialType}</p>
        </div>
      }
      <div style={{display:"flex", flexDirection:"row", /*maxWidth: "800px",*/ alignItems: "end"/*, gap: "10px"*/}}>
        <TextInput readOnly={false} fullWidth className='longer-input' name='walletAddress' labelText={t("issueCredential.walletAddress")} value={walletAddress} onChange={(e) => setWalletAddress(e as string)}></TextInput>
        <Button style={{marginBottom: "7px", minWidth: "250px"}} disabled={!walletAddress} onClick={() => onSendCredentialToWalletClick() }>{t("issueCredential.sentToWallet")}</Button>
      </div>
    
        
    </div>
  )
}

export default IssueCredential