import React, { useEffect, useState } from 'react'
import { Button, Dropdown, DropdownItem, LoadingSpinner, TextInput, Textarea } from 'suomifi-ui-components';

type Props = {}


type Itemstype = {
  type: string,
  format: string|undefined,
  items: Itemstype|undefined,
  "$ref": string|undefined,
}
type JsonSchema = {
  title: string, 
  type: string, 
  description: string,
  properties: Record<string, JsonSchema>,
  additionalProperties: unknown|undefined, // Record<string, JsonSchema>,
  format: string|undefined,
  items: Itemstype|undefined,
  "$ref": string|undefined
}

type JsonRootSchema = {
  "description": string,
  "@id": string,
  "title": string,
  "modified": string,
  "$schema": string,
  "type": string,
  "definitions": Record<string, JsonSchema>
}

const findSchema = (jsonSchema: JsonRootSchema, name: string): JsonSchema|undefined => {
    
  return jsonSchema?.definitions[name];
}
const generateTypeValue = (rootSchema: JsonRootSchema, property: JsonSchema|Itemstype, parents: string[]): object|string|number|null => {
  switch(property.type) {
    case "date":
      return new Date()
    case "string":
      if (property.format === "uri") {
        return "https://example.com";
      }
      if (property.format === "date") {
        return new Date().toISOString().substring(0,10)
      }
      if (property.format === "date-time") {
        return new Date().toISOString();
      } 
      return ""; // Use AI to guess the text?
    case "array":
      if (property.items) {
        return [generateTypeValue(rootSchema, property.items, parents)];
      }
      return [];
    case "object":
      if (property.$ref && property.$ref.startsWith("#/definitions/")) {
        const objType = property.$ref.substring(14);
        if (parents.indexOf(objType) >= 0) {
          return null; // {}; // avoid loop
        }
        return generateExample(rootSchema, findSchema(rootSchema, objType), [...parents, objType]);
      }
      return {};
    default:
      return "";
  }
}
const generateExample = (jsonRootSchema: JsonRootSchema, schema: JsonSchema|undefined, parents: string[]): object => {
  const result: Record<string, any> = {}
  if (schema?.additionalProperties !== undefined) {
    debugger;
    // dictionary! { "Alice": {...}, "Bob": {...}}
  }
  if (schema !== undefined && schema !== null && schema.properties) {
    
    Object.entries(schema.properties).forEach((entry) => {
      const key = entry[0];
      const property = entry[1];
      const value = generateTypeValue(jsonRootSchema, property, parents);
      
      result[key] = value;
       
    });
  }


  return result;
}

const CreateTemplateView = (props: Props) => {

  const [modelName, setModelName] = useState<string>("");
  const [lang, setLang] = useState<string>("en");
  const [jsonLdContext, setJsonLdContext] = useState<object>({});
  const [jsonSchema, setJsonSchema] = useState<JsonRootSchema|undefined>(undefined);
  const [rootDefinition, setRootDefinition] = useState<string>(""); 
  const [credentialSubject, setCredentialSubject] = useState<object>({});
  const [credentialJson, setCredentialJson] = useState<object>({});
  const [isCreatingTemplate, setIsCreatingTemplate] = useState<boolean>(false);
  const [isLoadingLd, setIsLoadingLd] = useState<boolean>(false);
  const [isLoadingSchema, setIsLoadingSchema] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(true);
       
  useEffect(() => {
    if (modelName !== "") {
      debugger;
      
      const modelNameEncoded = encodeURI(modelName);
      const tietomallitJsonLdContextUrl = `https://tietomallit.suomi.fi/datamodel-api/api/v1/exportModel?graph=http%3A%2F%2Furi.suomi.fi%2Fdatamodel%2Fns%2F${modelNameEncoded}&content-type=application%2Fld%2Bjson%2Bcontext&lang=${lang}&raw=true`;
      const tietomallitJsonSchemaUrl = `https://tietomallit.suomi.fi/datamodel-api/api/v1/exportModel?graph=http%3A%2F%2Furi.suomi.fi%2Fdatamodel%2Fns%2F${modelNameEncoded}&content-type=application%2Fschema%2Bjson&lang=${lang}&raw=true`; 
     
      setIsNew(true);
      fetch(`https://waltid.minisuomi.net/issuer-api/prh/config/templates/${modelName}`, { 
        method: "GET", 
        headers: {}
      }).then(response => response.json()).then(data => {
        if (data.credentialSubject) {
          setCredentialSubject(data.credentialSubject);
          setIsNew(false);
        }
      });
     
      setIsLoadingLd(true);
      // load json-ld from tietomallit
      fetch(tietomallitJsonLdContextUrl, {
        method: "GET", 
        headers: {},
      }).then(response => response.json()).then(data => {
        setJsonLdContext(data);
        setIsLoadingLd(false);
      });

      setIsLoadingSchema(true);
      // load json schema from tietomallit
      fetch(tietomallitJsonSchemaUrl, {
        method: "GET", 
        headers: {},
      }).then(response => response.json()).then(data => {
        setJsonSchema(data);
        setIsLoadingSchema(false);
      });
    }
    
  }, [lang, modelName]);

  useEffect(() => {

    const modelNameEncoded = encodeURI(modelName);
    const tietomallitJsonLdContextUrl = `https://tietomallit.suomi.fi/datamodel-api/api/v1/exportModel?graph=http%3A%2F%2Furi.suomi.fi%2Fdatamodel%2Fns%2F${modelNameEncoded}&content-type=application%2Fld%2Bjson%2Bcontext&lang=${lang}&raw=true`;
    const tietomallitJsonSchemaUrl = `https://tietomallit.suomi.fi/datamodel-api/api/v1/exportModel?graph=http%3A%2F%2Furi.suomi.fi%2Fdatamodel%2Fns%2F${modelNameEncoded}&content-type=application%2Fschema%2Bjson&lang=${lang}&raw=true`;
    
    if (JSON.stringify(jsonSchema) !== "{}" && JSON.stringify(jsonLdContext) !== "{}") {
      
      setCredentialJson({
        "type":[
          "VerifiableCredential",
          "VerifiableAttestation",
          modelName],
        "@context":["https://www.w3.org/2018/credentials/v1",
        tietomallitJsonLdContextUrl],
        "issuer":"",
        "issuanceDate": new Date().toUTCString(),//"2023-12-08T13:41:26Z",
        "issued":"2023-12-08T13:41:26Z",
        "validFrom":"2024-01-01T00:00:00Z",
        "credentialSchema":{
          "id": tietomallitJsonSchemaUrl,
          "type":"FullJsonSchemaValidator2021"
        },
        "credentialSubject": credentialSubject,
        "validUntil":"2025-01-01T00:00:00Z"
      });
    }

    

  }, [lang, modelName, jsonSchema, jsonLdContext, rootDefinition, credentialSubject]);

  

  useEffect(() => {
    if (jsonSchema && rootDefinition !== "") {
      debugger;
      const result = generateExample(jsonSchema, findSchema(jsonSchema, rootDefinition), []);
      setCredentialSubject(result||{});
      //getProperties(rootDefinition, jsonSchema);
    }
  }, [jsonSchema, rootDefinition])

  const createTemplate = async () => {
    setIsCreatingTemplate(true);
    await fetch(`https://waltid.minisuomi.net/issuer-api/prh/config/templates/${modelName}`, { 
      method: "POST", 
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentialJson) 
    });
    setIsCreatingTemplate(false);
  }

  // const getTemplate = async () => {
  //   setIsLoadingTemplate(true);
  //   await fetch(`https://waltid.minisuomi.net/issuer-api/prh/config/templates/${modelName}`, { 
  //     method: "GET", 
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(credentialJson) 
  //   });
  //   setIsLoadingTemplate(false);
  // }



  // useEffect(() => {
  //   if (rootDefinition !== "") {
  //     debugger;
  //     const result = generateExample(findSchema(rootDefinition), []);
  //     setCredentialSubject(result);
  //     //getProperties(rootDefinition, jsonSchema);
  //   }
  // }, [credentialSubject])

  // const onModelNameChange = (event: Even) => {
  //   setModelName(event.target.value)
  // }

  return (
    <form>
      <h2>Create/Edit credential template</h2>

      <Dropdown
        key="languageDropdown"
        labelText="Language"
        hintText="Select language for datamodel"
        visualPlaceholder="Select root definition"
        defaultValue={lang}
        onChange={e => setLang(e)}
      >
        <DropdownItem key="fi" value="fi">Finnish</DropdownItem>
        <DropdownItem key="en" value="en">English</DropdownItem>
      </Dropdown>

      

      <TextInput labelText="Model name" value={modelName} onChange={(e: any) => setModelName(e)}/>
      <br/>
      { isLoadingLd ?
        <LoadingSpinner status='loading' text={'Loading...'}></LoadingSpinner>
        :
        <Textarea fullWidth height={100} rows={10} labelText="JSON-ld content" value={JSON.stringify(jsonLdContext, null, 2)} />  
      }
      <br/>
      { isLoadingSchema ?
        <LoadingSpinner status='loading' text={'Loading...'}></LoadingSpinner>
        :
        <Textarea fullWidth height={100} rows={10} labelText="JSON Scema" value={JSON.stringify(jsonSchema, null, 2)} />
      }
      <br/>

      <Dropdown
        key="definitionDropdown"
        labelText="Definition"
        hintText="Select root definition to create example subject"
        visualPlaceholder="Select root definition"
        onChange={e => setRootDefinition(e)}
      >
        { jsonSchema && jsonSchema.definitions && Object.entries(jsonSchema.definitions)?.map(([key, value]: any) => (
          <DropdownItem key={key} value={key}>
            {value.title}
          </DropdownItem>
        ))}
      </Dropdown>

      <Textarea fullWidth height={100} rows={10} labelText="Credential example subject" value={JSON.stringify(credentialSubject, null, 2)} onChange={e => { try { setCredentialSubject(JSON.parse((e as any).target.value))} catch {}}} />
      <br/>
      <Textarea fullWidth rows={10} labelText="Credential Template for WaltId:" value={JSON.stringify(credentialJson, null, 2)} onChange={e => { try { setCredentialJson(JSON.parse((e as any).target.value))} catch {}}} />
    

      { isNew ?
        <Button disabled={isCreatingTemplate} onClick={createTemplate}>Create template</Button> :
        <Button disabled={isCreatingTemplate} onClick={createTemplate}>Edit template</Button>
    }
    
    </form>

  )
}

export default CreateTemplateView