import { useEffect, useState } from 'react'
import { Button, Checkbox, CheckboxGroup } from 'suomifi-ui-components'
import CreateTemplateView from './CreateTemplateView'

type Props = {}

type WaltIdTemplate = {
  "mutable": boolean,
  "name": string,
  "template": null
}

const AdminView = (props: Props) => {
  const [templates, setTemplates] = useState<WaltIdTemplate[]>([]);  
  const [checkedTemplateNames, setCheckedTemplateNames] = useState<string[]>([]);

  const issuer= "prh";
  //const templatesToIssue: string[] = ["eucc", "xbrlje", "agent3"]; // TODO load from database...
  useEffect(() => {
    fetch(`https://waltid.minisuomi.net/issuer-api/${issuer}/config/templates`, {
      method: "GET", 
      headers: {},
    }).then(response => response.json()).then(data => {
      setTemplates(data);
    })

    fetch(`https://waltid.minisuomi.net/issuer-api/${issuer}/config/getConfiguration`, {
      method: "GET", 
      headers: {},
    }).then(response => response.json()).then(data => {
      setCheckedTemplateNames(data.credentialTypes); // setCheckedTemplateNames(data.credentialTypes);
    })

  }, []);

  const saveSelections = () => {
    fetch(`https://waltid.minisuomi.net/issuer-api/${issuer}/config/setConfiguration`, {
      method: "POST", 
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "credentialTypes": checkedTemplateNames,
        "issuerApiUrl": `https://waltid.minisuomi.net/issuer-api/${issuer}`,
        "issuerClientName": "Walt.id Issuer Portal",
        "issuerDid": null, // TODO?
        "issuerUiUrl": `https://${issuer}.minisuomi.net`,
        "wallets": {
          "waltid": {
            "description": "walt.id web wallet",
            "id": "waltid",
            "presentPath": "api/siop/initiatePresentation/",
            "receivePath": "api/siop/initiateIssuance/",
            "url": "http://localhost:3000" 
          }
        }
      }) 
    }).then(response => {
      debugger
      return response.text()
    }).then(data => {
      console.log("setConfiguration successful")
    })

    
  };
 
  const onCheckBoxChecked = (name: string, checked: boolean)=> {
    debugger;
    if (checked) {
      setCheckedTemplateNames([name, ...checkedTemplateNames.filter(x => x !== name)]);
    }
    if (!checked) {
      setCheckedTemplateNames(checkedTemplateNames.filter(x => x !== name));
    }
  }
  return (
    <div>Templates

      <CheckboxGroup labelText="Templates to issue" className='column-count-3' >
        { templates.map(template => 
          <Checkbox key={template.name} onClick={(newState) => { onCheckBoxChecked(template.name, newState.checkboxState); }} value={template.name} checked={checkedTemplateNames.indexOf(template.name)>=0}>{template.name}</Checkbox>
          ) 
      }
      </CheckboxGroup>
      <Button onClick={saveSelections}>Save</Button>
    
      <CreateTemplateView></CreateTemplateView>
      
    
    </div>
    
  )
}

export default AdminView