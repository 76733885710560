
import { useTranslation } from 'react-i18next'
import { Form, useActionData, useNavigation } from 'react-router-dom'
import { Block, Button, Heading, LoadingSpinner, Paragraph, TextInput, Checkbox } from 'suomifi-ui-components'
import { CredentialsIssuanceResponse, WalletsAPI } from '../api/wallets-api/wallets-api'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'

const VERO_API_URL = process.env.REACT_APP_PRH_API_URL || process.env.PRH_API_URL || "/api";
const VERO_API_KEY = process.env.REACT_APP_PRH_API_KEY || process.env.PRH_API_KEY || "";

type Props = {}

interface IActionData {
  result?: CredentialsIssuanceResponse[],
  success: boolean|undefined,
  message: string|undefined,
  error: string|undefined
}

const CredentialsView = (props: Props) => {
  const { t } = useTranslation(); 
  const navigation = useNavigation();
  const data = useActionData() as IActionData;
  const loading = navigation.state === "loading";
  const submitting = navigation.state === "submitting";
  const [checkedTemplateNames, setCheckedTemplateNames] = useState<string[]>([]);
  const [checkedCredentialNames, setCheckedCredentialNames] = useState<string[]>([]);
  const issuer = "prh";
  
  useEffect(() => {
    
    fetch(`https://waltid.minisuomi.net/issuer-api/${issuer}/config/getConfiguration`, {
      method: "GET", 
      headers: {},
    }).then(response => response.json()).then(data => {    
      setCheckedTemplateNames(data.credentialTypes); // ["eucc", "xbrlje", "agent3"]
      setCheckedCredentialNames(data.credentialTypes);
    })

  }, []);

  const onCheckBoxChecked = (name: string, checked: boolean)=> {
    if (checked) {
      setCheckedCredentialNames([name, ...checkedCredentialNames.filter(x => x !== name)]);
    }
    if (!checked) {
      setCheckedCredentialNames(checkedCredentialNames.filter(x => x !== name));
    }
  }

  return (
    <Block>
      <Heading variant={'h1'}>{t("credentialsView.title")}</Heading>

      <Paragraph>{t("credentialsView.description")}</Paragraph>
      
      {/* <Paragraph>{t("credentialsView.formInfo")}</Paragraph> */}
      
      
      <Form method="post">
        <TextInput readOnly={false} className='shorter' name='companyCode' labelText={t("credentialsView.companyCode")} defaultValue="7011367-6"></TextInput>
        
        <input type='hidden' name="credentialNames" value={(checkedCredentialNames||[]).join(",")}></input>
        {
          // Make sure that credential name does not have special characters
          // and try to translate it
          checkedTemplateNames.map(x => <Checkbox key={x} onClick={(newState) => { onCheckBoxChecked(x, newState.checkboxState); }}  checked={checkedCredentialNames.indexOf(x)>=0}>{ t("credentialNames."+(x.match(/\w+/g)||[]).join("")).replace("credentialName.", "") }</Checkbox>)
        }
        <Button disabled={submitting||loading} variant="default" type="submit">{t("credentialsView.requestCredentialOffers")}</Button>
      

        {/* <TextInput readOnly={false} className='wider' name='walletAddress' labelText={t("credentialsView.walletAddress")} defaultValue="did:web:yrityslompakko.minisuomi.net:7011367-6"></TextInput>
      
        
        <Button disabled={submitting||loading} variant="default" type="submit">{t("credentialsView.sendToOrganizationWallet")}</Button> */}
      </Form>
      <Box mt={1}>
        { (loading || submitting || /*data?.success === true ||*/ data?.success === false) &&   
        <LoadingSpinner textAlign='right' text={ t(data?.success ? "credentialsView.success" : "requesting") } status={data?.success ? "success" : (data?.success === false ? "failed" : "loading")} /> 
        }
      </Box>
      
      { data?.result && data.result.map(x => 
        <div key={x.type}>
           { x.offer && 
            <a href={x.offer||"#"}>
              <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "256px", width: "256px" }}
                  value={x.offer}
                  viewBox={`0 0 256 256`}
                />
              <p>{ x.type || "" }</p>
            </a>
          }
        </div>
      )}
      
      {/* { data?.success && 
        <Paragraph>{ t("credentialsView.success") }</Paragraph>
      } */}
      { data?.error||"" }
    </Block>
  )
}


export const sendToCompanyWalletPostAction = async ({ request }: any) => {
  debugger;
  const data = Object.fromEntries(await request.formData());

  try {
    // TODO/NOTE do not do this on real production site with real data, it reveals api key on the browser query.
    const walletsApi = new WalletsAPI({ baseUrl: VERO_API_URL, baseApiParams: { headers: { "x-functions-key": VERO_API_KEY }}}); 
    // TODO get checkedTemplateNames from formData
    const result = await walletsApi.prh.prhRequestCredentials({ credentialTypes: data.credentialNames }, data.companyCode);
    //const result = await walletsApi.prh.prhSendCredentials({ credentialTypes: data.credentialNames, webDid: data.walletAddress }, data.companyCode);
    debugger;
    //const result = await walletsApi.prh.prhControllerSendCredentialWithWebDid({ credentialType: "eucc", webDid: data.walletAddress }, data.companyCode);
    if (result.ok) {
      debugger;
      //const data = await result.json();
      return { result: result.data, message: "Todistus lähetetty", success: true } as IActionData;  
    }
    return { message: "Todistus lähetetty?", success: false } as IActionData;
  }
  catch (err: any) {
    return { error: err?.error?.message || err?.message || "failed", success: false } as IActionData
  }
}

export default CredentialsView