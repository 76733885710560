import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import vceApi from '../../vceApi';
import { AuthProvider } from '../../provider/AuthProvider';
import { SessionInfo } from '../../api/vce-api/vce-api';


// Suomifi redirects to this page after successfull authentication or authorization
const SuomiFiAfterAuthView = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const wasHandled = useRef(false);

  const searchParams = new URLSearchParams(document.location.search)
  const authorization = searchParams.get("authorization");
  const authentication = searchParams.get("authentication");


  const lang = i18n.resolvedLanguage;

  /*if (authorization) {
    window.location.href = 
  }*/
  // if (authentication) {
  //   window.location.href = "https://test.minisuomi.fi/api/suomifi/ypa/trigger?lang=fi";
  // }

  useEffect(() => {
    console.log(wasHandled);
    if (!wasHandled.current) {
      wasHandled.current = true;
      if (authentication) {
        // Get also company information:
        const path = window.location.origin + "/suomifi?authorization=true";
        vceApi.suomifi.triggerYpa({ lang: lang as "fi"|"en" || "fi", returnUrl: path }, { credentials: "include" }).then(x => {
          window.location.href = x.data.redirectUrl
        });
        

        // vceApi.sessions.getSessionInfo({ credentials: "include" }).then(x => {
        //   x.json().then(data => {
        //     const username = data.givenNames;
        //     const companyName = data.authorizationRoles.length > 0 ? data.authorizationRoles[0].name : "";
        //     const companyId = data.authorizationRoles.length > 0 ? data.authorizationRoles[0].identifier : "";
        //     localStorage.setItem("user", username);
        //     localStorage.setItem("company", companyName);
        //     localStorage.setItem("companyId", companyId);
        //     localStorage.setItem("authorizationRoles", JSON.stringify(data.authorizationRoles)); // TODO?
        //     localStorage.setItem("refreshTime", (new Date().getTime()/1000).toString());
        //     userContext.setName(username);
        //     //userContext.setCompany(companyName);
        //     //userContext.setCompanyId(companyId);
        //     userContext.setIsLoggedIn(true); 
        //     navigate("/");
        //   }).catch(response => {
        //     console.log("session not found!");
        //     console.log(response);
        //     navigate("/login");
        //   })
        // })
      }
      else if (authorization) {
        vceApi.sessions.getSessionInfo({ credentials: "include" }).then(x => {
          x.json().then((data: SessionInfo) => {
            
            AuthProvider.setSessionInfo(data);
            
            //AuthProvider.signin("testt");
            //AuthProvider.useSessionInfo(data);
            //AuthProvider.useSession(data);
            //AuthProvider.useCompany(companyId);
            /*userContext.setName(username);

            userContext.setCompany(companyName);
            userContext.setCompanyId(companyId);
            userContext.setIsLoggedIn(true);*/ 
            navigate("/");
          }).catch(response => {
            console.log("session not found!");
            console.log(response);
            navigate("/login");
          });
        })
      }
    }

  }, [navigate, lang, authorization, authentication]);

  return (
    <div></div>
  )
}

export default SuomiFiAfterAuthView
